<template>
  <div>
    <a-spin :spinning="loadingAgentAllocationOrder">
      <a-breadcrumb class="bread-crumb">
        <a-breadcrumb-item>
          <router-link :to="{ name: 'agency_monthly_bills' }">
            月账单
          </router-link>
        </a-breadcrumb-item>

        <a-breadcrumb-item>
          <router-link :to="{ name: 'agency_monthly_bill_info', params: { monthly_bill_id: billId }}">
            {{ agentAllocationOrder.month }}
          </router-link>
        </a-breadcrumb-item>

        <a-breadcrumb-item> {{ agentAllocationOrder.no }}</a-breadcrumb-item>
      </a-breadcrumb>

      <a-descriptions
        :column="3"
        class="iotplt-descriptions iotplt-descriptions-max-7"
      >
        <a-descriptions-item label="账单批次">
          {{ agentAllocationOrder.no }}
        </a-descriptions-item>

        <a-descriptions-item label="卡号数量">
          {{ agentAllocationOrder.cards_count | formatBigNumber }}
        </a-descriptions-item>

        <a-descriptions-item label="卡板费用(元)">
          {{ agentAllocationOrder.card_fee | formatCurrency }}
        </a-descriptions-item>

        <a-descriptions-item label="账单时间">
          {{ agentAllocationOrder.created_at }}
        </a-descriptions-item>

        <a-descriptions-item label="套餐费用(元)">
          {{ agentAllocationOrder.product_fee | formatCurrency }}
        </a-descriptions-item>

        <a-descriptions-item label="账单金额(元)">
          {{ agentAllocationOrder.total_fee | formatCurrency }}
        </a-descriptions-item>
      </a-descriptions>
    </a-spin>

    <search-sim-card-allocation-order @submit="submitSearch" />

    <div class="iotplt-table-operation-buttons">
      <a-button type="primary" @click="exportData">导出</a-button>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      row-key="id"
    >
      <span slot="product_price_title">
        <a-tooltip>
          <template slot="title">
            这里显示的是套餐周期单价，精确到两位小数
          </template>
          套餐价格(元)
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import { findAgentAllocationOrder, findSimCardAllocationOrders, exportSimCardAllocationOrders } from '@/api/agent_allocation_order'
import SearchSimCardAllocationOrder from '@/views/agent_monthly_bills/allocation/top_agent_view_agency/show/Search'
import { formatBigNumber, formatCurrency } from '@/utils/filter'
import Pagination from '@/components/Pagination'
import { exportExcel } from '@/api/excel'

export default {
  name: 'ShowSimCardAllocationOrders',
  components: {
    SearchSimCardAllocationOrder,
    Pagination
  },
  data() {
    return {
      data: [],
      agentAllocationOrder: {},
      loading: true,
      loadingAgentAllocationOrder: false,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          title: 'ICCID/SIMID',
          dataIndex: 'iccid',
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'iccid' ? this.sort.sort_order : false,
          scopedSlots: { customRender: 'iccid' }
        },
        {
          title: '手机号码',
          dataIndex: 'phone_number'
        },
        {
          title: '客户套餐',
          width: 220,
          dataIndex: 'agents_product_name'
        },
        {
          slots: { title: 'product_price_title' },
          dataIndex: 'product_price',
          customRender: formatCurrency
        },
        {
          title: '周期数',
          dataIndex: 'cycles',
          customRender: formatBigNumber
        },
        {
          title: '套餐费用(元)',
          dataIndex: 'product_fee',
          customRender: formatCurrency
        },
        {
          title: '卡板费用(元)',
          dataIndex: 'card_fee',
          customRender: formatCurrency
        },
        {
          title: '总费用(元)',
          dataIndex: 'total_fee',
          customRender: formatCurrency
        }
      ]
    },

    agentAllocationOrderId() {
      return parseInt(this.$route.params.allocation_order_id)
    },

    billId() {
      return parseInt(this.$route.query.bill_id)
    }
  },
  created() {
    this.fetchData()
    this.fetchSimCardAllocationOrder()
  },
  methods: {
    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchSimCardAllocationOrder()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchSimCardAllocationOrder()
    },

    fetchData() {
      this.loadingAgentAllocationOrder = true
      findAgentAllocationOrder(this.agentAllocationOrderId).then((res) => {
        if (res.code === 0) {
          this.agentAllocationOrder = res.data
        }
        this.loadingAgentAllocationOrder = false
      })
    },

    fetchSimCardAllocationOrder() {
      this.loading = true
      findSimCardAllocationOrders(this.agentAllocationOrderId, Object.assign({},
        this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    exportData() {
      exportSimCardAllocationOrders(this.agentAllocationOrderId, this.query).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.bread-crumb {
  margin-bottom: 20px;
}
</style>
